import { defineMessages } from 'gatsby-theme-fast-ai-sidebar';

export default defineMessages({
	heading: {
		id: 'page.baas.heading',
		defaultMessage: 'Behavior-as-a-Service',
	},
	title: {
		id: 'page.baas.title',
		defaultMessage: 'Behavior as a Service',
	},
	description: {
		id: 'page.baas.description',
		defaultMessage:
			'Use detailed <strong>behavioral signals</strong> in real-time in order to reveal <strong>suspicious and fraudulent</strong> users during online loans or in insurance claims processes.',
	},

	whatCanDoHeading: {
		id: 'page.baas.whatCanDoHeading',
		defaultMessage: 'What <strong>Can</strong> It Do?',
	},
	howDoesItHeading: {
		id: 'page.baas.howDoesItHeading',
		defaultMessage: 'How Does It <strong>Work</strong>?',
	},
	helpHeading: {
		id: 'page.baas.helpHeading',
		defaultMessage: 'Who <strong>Can</strong> It Help?',
	},

	whatItCanA: {
		id: 'page.baas.whatItCanA',
		defaultMessage:
			'Increase approval rate for prospective clients with no credit history but the digital footprint',
	},
	whatItCanB: {
		id: 'page.baas.whatItCanB',
		defaultMessage: 'Prevent defaults to minimize losses with Smart Signals',
	},
	whatItCanC: {
		id: 'page.baas.whatItCanC',
		defaultMessage:
			'Personalise customer journey based on Smart Features and provide better offerings',
	},
	whatItCanD: {
		id: 'page.baas.whatItCanD',
		defaultMessage:
			'Strengthen current scoring/risk models with extended data - Smart Features',
	},
	onlineLoansHeading: {
		id: 'page.baas.onlineLoansHeading',
		defaultMessage: 'Online Loans',
	},
	onlineLoansA: {
		id: 'page.baas.onlineLoansA',
		defaultMessage:
			'Increase approval rate or reveal fraudulent or anomalous loan applications.',
	},
	onlineInsuranceHeading: {
		id: 'page.baas.onlineInsuranceHeading',
		defaultMessage: 'Insurance',
	},
	onlineInsuranceA: {
		id: 'page.baas.onlineInsuranceA',
		defaultMessage:
			"Reveal fraudulent and anomalous online claims or assess user's risk during new insurance calculation.",
	},
	onlineECommerceHeading: {
		id: 'page.baas.onlineECommerceHeading',
		defaultMessage: 'E-commerce',
	},
	onlineECommerceA: {
		id: 'page.baas.onlineECommerceA',
		defaultMessage:
			'Predict what will be the next shopping stage or predict whether goods will be returned',
	},

	howDoesItPerex: {
		id: 'page.baas.howDoesItPerex',
		defaultMessage: `Zoe.ai collects user’s online behavioral data during visit on
		website or mobile site. Interactions are detailed data about
		user's behavior on website, e.g., what and how web pages have
		been interacted, how form is being filled, device fingerprints,
		input device biometry, locations and transaction data.`,
	},
	howDoesItDescription: {
		id: 'page.baas.howDoesItDescription',
		defaultMessage:
			'<p>Zoe.ai aggregates and enriches these raw interactions and provides back extensive set of aggregated data called Smart Features.</p>' +
			'<p>Behavioral data are also interpreted by advanced AI Machine Learning algorithms in order to provide accurate signals called Smart Signals as a result from Zoe.ai.</p>' +
			'<p> Thanks to advanced technology Smart Features and Smart Signals are provided in real-time through modern programmatic API. This allows to integrate Zoe.ai into real-time online business processes.</p>',
	},
});
