/* eslint-disable react/prop-types,react/display-name */
import React, { Fragment } from 'react';
import { Paragraph } from 'gatsby-theme-fast-ai-sidebar/src/mdxComponents';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-theme-fast-ai-sidebar';
import {
	Box,
	Col,
	Heading,
	Image as Img,
	Row,
	Text,
} from '@fast-ai/ui-components';
import BulbGraphIcon from '@fast-ai/dashboard-icons/src/BusinessAndFinance1Asset25';
import CircleIcon from '@fast-ai/dashboard-icons/src/Programming4Asset20';
import AlgIcon from '@fast-ai/dashboard-icons/src/Programming3Asset21';
import Waves from '@fast-ai/ui/Waves';
import Relative from '@fast-ai/ui/Relative';
import ZoeBaasAnimation from '@fast-ai/ui/ZoeBaasAnimation';

import howDoesItWork1 from '../../content/assets/how-it-works-phase-1.svg';
import howDoesItWork1m from '../../content/assets/how-it-works-phase-1-mobile.svg';
import howDoesItWork2 from '../../content/assets/how-it-works-phase-2.svg';
import howDoesItWork2m from '../../content/assets/how-it-works-phase-2-mobile.svg';
import { DefaultLayout } from '../layout';
import { CompaniesBrands, DotMenuItem, Menu, Section, Seo } from '../ui';
import { ContactSection } from '../view/landing';
import { RichFormattedMessage, useTranslateMessage } from '../intl';
import m from '../intl/messages/pageBaas';

const styles = {
	heroHeading: {
		// fontSize: [7, 7, 8, 9],
		// 49, 85
		fontSize: [6, 7, 8, '64px'],
		lineHeight: 1,
		mb: [2, 2, 3, 3],
		maxWidth: '40rem',
	},
	perex: {
		fontSize: [4, 4, 5],
		maxWidth: '38rem',
		mb: [2, 2, 3, 3],
	},
	actions: {
		display: 'flex',
		mb: [2, 2, 3, 3],
		justifyContent: ['center', 'center', 'flex-start'],
		'a + button': {
			ml: 2,
		},
	},
	bigButton: {
		py: [2, 3],
		px: [3, 4],
	},
};

const whatItCan = [
	<RichFormattedMessage {...m.whatItCanA} />,
	<RichFormattedMessage {...m.whatItCanB} />,
	<RichFormattedMessage {...m.whatItCanC} />,
	<RichFormattedMessage {...m.whatItCanD} />,
];
const helpData = [
	{
		heading: <RichFormattedMessage {...m.onlineLoansHeading} />,
		items: [<RichFormattedMessage {...m.onlineLoansA} />],
	},
	{
		heading: <RichFormattedMessage {...m.onlineInsuranceHeading} />,
		items: [<RichFormattedMessage {...m.onlineInsuranceA} />],
	},
	{
		heading: <RichFormattedMessage {...m.onlineECommerceHeading} />,
		items: [<RichFormattedMessage {...m.onlineECommerceA} />],
	},
];
const BaaSPage = ({ data, location, ...rest }) => {
	const translate = useTranslateMessage();
	return (
		<DefaultLayout {...rest} location={location}>
			<Seo title={translate(m.title)} />
			<Section>
				<Row
					sx={{
						alignItems: 'center',
						flexWrap: 'wrap',
						textAlign: ['center', 'center', 'left'],
						py: [5, 5, 6],
					}}
				>
					<Col span={[12, 12, 8, 8]}>
						<Heading sx={styles.heroHeading}>
							<RichFormattedMessage {...m.heading} />
						</Heading>
						<Text as="p" sx={styles.perex}>
							<RichFormattedMessage {...m.description} />
						</Text>
						<Box
							sx={{
								position: ['static', 'static', 'absolute'],
								bottom: '50%',
								transform: ['none', 'none', 'translateY(50%)'],
								right: 0,
								mx: 'auto',
							}}
						>
							<ZoeBaasAnimation />
						</Box>
					</Col>
				</Row>
			</Section>

			<Section variant="stripesSecondary">
				<Row sx={{ flexWrap: 'wrap' }}>
					<Col span={12}>
						<Heading>
							<RichFormattedMessage {...m.whatCanDoHeading} />
						</Heading>
					</Col>
				</Row>
				<Row>
					<Col span={3}>
						<Box as={BulbGraphIcon} sx={{ color: 'primary' }} size={128} />
					</Col>
					<Col span={9}>
						<Menu sx={{ flexDirection: 'column', textAlign: 'left' }}>
							{whatItCan.map((pointText, i) => (
								<DotMenuItem sx={{ mb: 2 }} key={i}>
									<Text>{pointText}</Text>
								</DotMenuItem>
							))}
						</Menu>
					</Col>
				</Row>
			</Section>

			<Section
				variant="stripesSecondary"
				sx={{
					'.gatsby-image-wrapper': {
						borderRadius: 'basic',
						border: t => t.borders.bold,
					},
					position: 'relative',
				}}
			>
				<Row>
					<Col span={[0, 0, 3]} />
					<Col span={[12, 12, 9]}>
						<Box
							sx={{
								maxWidth: '900px',
								position: 'relative',
								zIndex: 1,
							}}
						>
							<GatsbyImage
								image={data.dashboard.childImageSharp.gatsbyImageData}
								alt="Zoe - Dashboard Screenshot"
								title="Zoe - Dashboard Screenshot"
							/>
						</Box>
						<Box
							sx={{
								position: 'absolute',
								bottom: ['-10%', '-10%', 0],
								left: ['-0%', '-0%', '10%'],
								width: ['200px', '200px', '200px', '280px'],
								zIndex: 1,
							}}
						>
							<GatsbyImage
								image={data.dashboardPhone.childImageSharp.gatsbyImageData}
								alt="Zoe - Dashboard Mobile Screenshot"
								title="Zoe - Dashboard Mobile Screenshot"
							/>
						</Box>
					</Col>
				</Row>
			</Section>

			<Relative
				sx={{ zIndex: 0, top: ['calc(-2rem)', 'calc(-2rem)', 'calc(-4rem)'] }}
			>
				<Waves
					sx={{
						zIndex: 0,
						position: 'absolute',
						left: 0,
						right: 0,
						top: 0,
						width: '100%',
						height: 'auto',
						transform: 'translateY(-50%)',
					}}
				/>
			</Relative>

			<Section variant="stripesSecondary">
				<Row sx={{ flexWrap: 'wrap' }}>
					<Col span={12}>
						<Heading>
							<RichFormattedMessage {...m.helpHeading} />
						</Heading>
					</Col>
				</Row>
				<Row>
					<Col span={3}>
						<Box as={CircleIcon} sx={{ color: 'primary' }} size={128} />
					</Col>
					<Col span={9}>
						{helpData.map(({ heading, items }, i) => (
							<Fragment key={i}>
								<Heading
									as="h2"
									sx={{ textAlign: 'left' }}
									variant="subHeading1"
								>
									{heading}
								</Heading>

								<Menu sx={{ flexDirection: 'column', textAlign: 'left' }}>
									{items.map((pointText, i) => (
										<DotMenuItem sx={{ mb: 2 }} key={i}>
											<Text>{pointText}</Text>
										</DotMenuItem>
									))}
								</Menu>
							</Fragment>
						))}
					</Col>
				</Row>
			</Section>
			<Section variant="stripesPrimary">
				<Row sx={{ flexWrap: 'wrap' }}>
					<Col span={12}>
						<Heading>
							<RichFormattedMessage {...m.howDoesItHeading} />
						</Heading>
					</Col>
				</Row>
				<Row>
					<Col span={3}>
						<Box as={AlgIcon} sx={{ color: 'primary' }} size={128} />
					</Col>
					<Col span={9}>
						<Box sx={{ textAlign: 'left' }}>
							<Paragraph>
								<Img
									sx={{
										my: 2,
										display: ['none', 'none', 'block'],
									}}
									src={howDoesItWork1}
									alt="ZOE.ai - How it works graph - Collects applicant's behavioral data..."
									title="ZOE.ai - How it works graph - Collects applicant's behavioral data..."
								/>
								<Img
									sx={{
										my: 2,
										display: ['block', 'block', 'none'],
									}}
									src={howDoesItWork1m}
									alt="ZOE.ai - How it works graph - Collects applicant's behavioral data..."
									title="ZOE.ai - How it works graph - Collects applicant's behavioral data..."
								/>

								<RichFormattedMessage {...m.howDoesItPerex} />
							</Paragraph>

							<Img
								sx={{
									my: 2,
									display: ['none', 'none', 'block'],
								}}
								src={howDoesItWork2}
								alt="ZOE.ai - How it works graph - Collects applicant's behavioral data..."
								title="ZOE.ai - How it works graph - Collects applicant's behavioral data..."
							/>
							<Img
								sx={{
									my: 2,
									display: ['block', 'block', 'none'],
								}}
								src={howDoesItWork2m}
								alt="ZOE.ai - How it works graph - Collects applicant's behavioral data..."
								title="ZOE.ai - How it works graph - Collects applicant's behavioral data..."
							/>
							<RichFormattedMessage {...m.howDoesItDescription} />
						</Box>
					</Col>
				</Row>
			</Section>
			<Section variant="stripesSecondary">
				<CompaniesBrands location={location} />
			</Section>
			<ContactSection />
		</DefaultLayout>
	);
};

export const query = graphql`
	{
		dashboardPhone: file(relativePath: { eq: "dashboard-phone@2x.png" }) {
			childImageSharp {
				gatsbyImageData(width: 900, quality: 70, layout: CONSTRAINED)
			}
		}
		dashboard: file(relativePath: { eq: "dashboard@2x.png" }) {
			childImageSharp {
				gatsbyImageData(width: 900, quality: 70, layout: CONSTRAINED)
			}
		}
	}
`;

export default BaaSPage;
