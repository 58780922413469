import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@fast-ai/ui-components';

const Relative = ({ sx, ...rest }) => (
	<Box sx={{ position: 'relative', ...sx }} {...rest} />
);
Relative.propTypes = { sx: PropTypes.object };

export default Relative;
