/* eslint-disable max-len */
import React, { Fragment } from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
	<Fragment>
		<g
			fill="none"
			stroke="currentColor"
			strokeMiterlimit="10"
			strokeWidth="2"
			data-name="Layer 2"
		>
			<circle cx="16" cy="16" r="15" strokeDasharray="54 2 2 2 2 2" />
			<circle cx="16" cy="16" r="8" />
			<path d="M13 1.31v7.28M19 1.31v7.28M13 23.41v7.28M19 23.41v7.28M1.31 19h7.28M1.31 13h7.28M23.41 19h7.28M23.41 13h7.28" />
		</g>
	</Fragment>,
	32,
	'Programming4Asset20'
);
