/* eslint-disable max-len */
import React, { memo } from 'react';
import { keyframes } from '@emotion/core';
import { Box } from '@fast-ai/ui-components';

const waveMove = keyframes`
    0% {
        stroke-dashoffset: 1000
    }

    100% {
        stroke-dashoffset: 0
    }
}
`;

const wave = {
	opacity: 0.5,
	strokeWidth: 3,
	strokeDasharray: '40%',
	strokeDashoffset: '2100',
	strokeLinecap: 'round',
	animationDuration: '6s',
	animationTimingFunction: 'linear',
	animationIterationCount: 'infinite',
	animationDirection: 'normal',
	animationFillMode: 'both',
	animationPlayState: 'running',
	animationName: waveMove,
};
const Waves = props => (
	<Box as="svg" viewBox="0 0 1700 476" version="1.1" {...props}>
		<Box
			as="path"
			sx={{
				...wave,
				animationDelay: '0s',
				stroke: 'primary',
			}}
			d="M1700.71,1.705c-62.618,62.998 -136.216,118.578 -223.531,135.041c-25.436,4.796 -51.427,4.989 -76.953,4.183c-33.341,-1.053 -66.626,-4.483 -99.55,-9.818c-31.567,-5.116 -63.049,-11.315 -94.965,-14.409c-20.21,-1.959 -40.549,-2.815 -60.816,-2.834c-22.426,0.029 -44.758,6.613 -64.173,18.209c-44.655,26.671 -75.49,71.716 -118.493,101.112c-29.006,19.829 -63.127,31.615 -99.064,32.714c-36.97,1.13 -74.597,-4.696 -108.323,-20.885c-35.048,-16.824 -64.767,-43.529 -102.512,-57.533c-27.768,-10.303 -57.937,-13.415 -87.502,-13.525c-40.041,-0.193 -80.036,-3.741 -118.669,-13.779c-26.395,-6.858 -52.316,-15.64 -79.188,-20.298c-45.512,-7.89 -93.066,-8.685 -136.815,0.412c-86.4,17.967 -162.7,68.844 -229.508,125.468l-1.294,-1.526c45.574,-38.625 94.796,-73.542 148.691,-98.367c43.057,-19.833 89.484,-33.103 137.426,-33.838c38.611,-0.592 77.161,3.221 114.421,13.088c29.333,7.768 58.08,17.191 88.729,21.715c26.874,3.966 53.827,5.035 81.361,5.136c36.901,0.227 73.684,6.989 106.932,24.29c32.665,16.996 61.039,41.516 96.937,54.614c28.313,10.33 58.943,13.894 88.834,13.041c33.531,-0.957 66.515,-11.471 94.574,-29.81c35.382,-23.123 62.987,-56.704 94.775,-83.612c17.187,-14.549 36.479,-27.236 57.855,-33.609c20.297,-6.052 41.822,-5.338 63.199,-4.442c56.475,2.366 111.913,15.849 166.715,22.136c31.616,3.626 63.293,5.542 95.333,4.693c68.284,-1.81 134.332,-32.833 189.026,-73.283c6.708,-4.961 13.299,-10.079 19.779,-15.335c19.378,-15.715 37.712,-32.613 55.351,-50.359c0.473,0.47 0.945,0.94 1.418,1.41Z"
			fill="none"
		/>
		<Box
			as="path"
			sx={{
				...wave,
				animationDelay: '1s',
				stroke: '#f5a623',
			}}
			d="M1133.47,192.004c39.965,0.213 79.794,7.225 116.222,25.473c26.853,13.452 51.631,31.222 80.082,41.709c41.645,15.351 87.599,15.062 132.589,10.068c80.251,-8.907 157.723,-36.959 229.913,-73.432c2.428,-1.227 4.85,-2.466 7.269,-3.711l0.916,1.778c-88.427,45.532 -184.091,78.632 -282.771,80.237c-35.823,0.583 -71.78,-4.451 -104.871,-19.855c-31.547,-14.685 -59.629,-35.937 -93.535,-47.441c-27.704,-9.4 -57.362,-12.735 -86.679,-12.829c-97.021,-0.103 -194.043,-0.152 -291.064,0.001c-49.026,0.129 -97.982,5.881 -146.486,17.946c-27.699,6.89 -55.297,14.4 -83.9,18.193c-49.761,6.597 -100.741,4.006 -149.322,-6.299c-33.937,-7.199 -67.397,-17.271 -101.681,-22.945c-29.745,-4.924 -59.983,-7.173 -90.089,-6.89c-49.205,0.463 -96.747,24.739 -134.523,54.959c-53.948,43.156 -96.523,99.658 -134.157,157.742l-0.54,0.835l-1.68,-1.086c43.381,-67.081 92.97,-132.912 159.571,-176.906c32.987,-21.79 71.355,-37.168 111.31,-37.544c43.097,-0.405 86.289,4.773 128.385,14.565c32.601,7.584 64.896,16.842 98.03,21.303c49.322,6.64 99.999,4.714 147.86,-5.584c25.56,-5.5 50.709,-12.913 76.45,-18.367c39.589,-8.388 80.24,-11.815 120.767,-11.922c97.311,-0.154 194.623,-0.31 291.934,0.002Z"
			fill="none"
		/>
		<Box
			as="path"
			sx={{
				...wave,
				animationDelay: '2s',
				stroke: 'secondary',
			}}
			d="M844.117,208.001c38.776,0.144 77.622,7.751 112.696,27.195c31.323,17.364 59.158,40.613 93.536,52.893c28.09,10.033 58.589,12.429 88.199,12.67c38.796,0.358 77.584,3.989 115.296,13.862c26.516,6.942 52.406,15.774 79.187,20.452c45.508,7.948 93.066,8.749 136.812,-0.416c61.294,-12.842 118.358,-43.467 168.477,-78.761c21.108,-14.864 41.383,-30.876 61.031,-47.656l1.298,1.52c-45.382,38.76 -94.089,73.775 -148.691,99.113c-43.085,19.995 -89.654,33.356 -137.428,34.094c-38.56,0.596 -77.114,-3.232 -114.423,-13.187c-29.316,-7.822 -58.078,-17.32 -88.728,-21.879c-26.871,-3.996 -53.825,-5.073 -81.361,-5.175c-36.465,-0.226 -73.023,-6.885 -106.003,-23.989c-34.068,-17.667 -63.609,-43.636 -101.103,-56.67c-32.395,-11.261 -67.913,-14.419 -100.901,-10.97c-39.125,4.091 -75.966,20.921 -108.902,43.476c-33.792,23.143 -63.744,51.573 -97.925,74.899c-28.487,19.44 -60.693,33.877 -95.537,33.811c-1.332,-0.002 -2.664,-0.008 -3.996,-0.018c-30.505,-0.232 -61.023,-2.927 -90.959,-9.071c-23.478,-4.819 -46.776,-10.604 -70.454,-13.795c-30.32,-4.085 -61.055,-5.723 -91.451,-4.662c-76.362,2.667 -148.354,41.529 -206.724,89.224c-19.382,15.837 -37.713,32.862 -55.351,50.741l-1.424,-1.404c62.282,-63.134 135.186,-118.923 222.48,-135.862c23.745,-4.608 48.102,-5.194 71.991,-4.578c30.871,0.795 61.664,3.716 92.133,9.903c28.57,5.801 56.813,12.871 86.457,15.535c33.744,3.033 67.992,4.314 98.957,-7.998c42.246,-16.797 76.233,-49.258 111.973,-77.19c32.539,-25.431 67.717,-48.625 107.631,-59.29c17.299,-4.622 35.213,-6.839 53.207,-6.817Z"
			fill="none"
		/>
	</Box>
);
export default memo(Waves);
