/* eslint-disable max-len */
import React, { Fragment } from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
	<Fragment>
		<g
			fill="none"
			stroke="currentColor"
			strokeMiterlimit="10"
			strokeWidth="2"
			data-name="Layer 2"
		>
			<path d="M11 1h10v4H11zM11 9h10v4H11zM11 27h10v4H11z" />
			<circle cx="4" cy="11" r="3" />
			<circle cx="28" cy="16" r="3" />
			<path d="M4 8V3h7M20.5 20L16 23l-4.5-3 4.5-3 4.5 3zM11 11H7" />
			<path d="M12 20H4v-6M16 5v4M16 13v4M16 23v4M28 6v7M28 2v2M21 3h4M25 29h-4M28 28v2M28 19v7" />
		</g>
	</Fragment>,
	32,
	'Programming3Asset21'
);
